/* =============================================================================== *\
	TABLE OF CONTENTS
\* =============================================================================== */


/*
	1. DEPENDENCIES
	2. GLOBAL
	3. FRAME
	4. COMPONENTS
*/




/* =============================================================================== *\
	1. DEPENDENCIES
\* =============================================================================== */


@import "core";
@import "options";




/* =============================================================================== *\
	2. GLOBAL
\* =============================================================================== */


body {
	margin: 0;

	font-size: $font-size;
	font-family: $primary-font, $fallback-font;
	color: $text-color;

	cursor: default;
}


html, body {
	height: 100%;
}


h1, h2, h3, h4, h5, h6 {
	margin: 0;

	font-size: $font-size;
	font-weight: normal;
}


p {
	line-height: 30px;
}


a {
	text-decoration: none;
	color: $accent-color;

	transition: color $short-transition;

	&:hover { color: $primary-color; }
}


ul {
	line-height: 30px;
}




/* =============================================================================== *\
	3. FRAME
\* =============================================================================== */


/*

#header
	#nav
#banner
#main
#footer

*/


#wrapper {
	min-height: 100%;
	margin-bottom: -160px; // TODO: Hard coded (60 + 20 + 20 + 60)

	&::after {
		content: "";
		display: block;

		height: 160px; // TODO: Hard coded (60 + 20 + 20 + 60)
	}
}


#header {
	// TODO
	width: $wrapper-width;
	//max-width: 100%;
	height: $header-height;
	margin: 5px auto;
	padding: 0 20px;
	box-sizing: border-box;

	img, h1, a { float: left; }

	img {
		height: $header-height - 20;

		padding: 10px 0;
	}

	h1 {
		line-height: 30px;
		padding: 20px;

		text-transform: uppercase;
		letter-spacing: .04em;
		font-weight: 200;
		font-size: 1.4em;
		color: $primary-color;
	}
}

	#nav {
		float: right;

		height: 30px;
		line-height: 20px;
		padding: 20px 0;

		a {
			margin-left: 10px;
			padding: 5px;

			text-transform: uppercase;
			letter-spacing: .03em;
			font-size: .9em;
			color: inherit;
		}

		.menu-item-cta {
			padding-left: 10px;
			padding-right: 10px;

			background-color: $light-primary-color;
			color: #fff;
		}
	}


#banner {
	min-width: $wrapper-width;
	height: 280px;

	background-image: url(../res/banner.png);
}


#main {
	width: $wrapper-width;
	//max-width: 100%;
	margin: 0 auto;
	padding: $main-padding;
	box-sizing: border-box;

	h1 {
		margin-bottom: 60px;

		text-align: center;
		letter-spacing: .08em;
		font-size: 3.5em;
		font-weight: 200;
		color: $primary-color;
	}

	h2 {
		margin: 16px 0;

		font-size: 2em;
	}

	section {
		margin-bottom: 80px;
	}

	img {
		max-width: 100%;

		box-shadow: 0 1px 5px rgba(0,0,0,.4);
	}
}


#footer {
	min-width: $wrapper-width;
	padding: $footer-padding 0;

	text-align: center;
	background-color: $footer-color;
	color: #606060;

	a { color: #8f8f8f; }
}


/*@media all and (max-width: 800px)
{
	#nav {
		clear: left;
	}
}*/




/* =============================================================================== *\
	4. COMPONENTS
\* =============================================================================== */


.button {
	display: inline-block;

	padding: 20px 26px;
	border: 3px solid $primary-color;

	text-align: center;
	text-transform: uppercase;
	color: $primary-color;

	transition: background-color $short-transition, color $short-transition;

	&:hover {
		background-color: $light-primary-color;
		color: #fff;
	}
}

#home-button-cta {
	/* only used for the CTA on the home page */
	
	@extend .button;

	display: block;
	margin-top: 32px;

	background-color: $light-primary-color;
	color: #fff;
}


.icon {
	width: 80px;
	height: 80px;
	margin: 0 auto;
	padding: 20px;
	border: 3px solid $accent-color;
	border-radius: 50%;

	fill: $accent-color;
}