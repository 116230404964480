$wrapper-width: 1000px;
$header-height: 70px;
//$header-padding: 5px 20px;
$main-padding: 40px 20px;
$footer-padding: 60px;

$font-size: 16px;
$primary-font: "Source Sans Pro";
$fallback-font: Tahoma, sans-serif;

$primary-color: #000080;
$light-primary-color: #363699;
$accent-color: #5677fc;
$text-color: #323232;
$footer-color: #252525;

$short-transition: .3s ease-out;