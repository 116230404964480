@mixin row {
	overflow: hidden; // clearfix

	// Columns.
	> * {
		float: left;
		box-sizing: border-box;
	}
}


@mixin share($nb-columns, $gutter-size) {
	@include row;

	text-align: center;

	> * {
		width: (100% - $gutter-size * ($nb-columns - 1)) / $nb-columns;

		margin-left: $gutter-size;

		&:first-child { margin-left: 0; }
	}
}




.row { @include row; }

.share-3 { @include share(3, 2%); }

.share-4 { @include share(4, 0%); }

/*@media all and (max-width: 700px)
{
	.share-3 > * {
		width: 100%;
		margin-left: 0;
		margin-bottom: 20px;
	}
}*/